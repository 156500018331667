// @ts-ignore

import clsx from "clsx"
import createStyle from "lib/createStyle"
import { H5 } from "styles/Type"

const UserFormWrapper = createStyle(
  "div",
  "flex justify-center items-center grid grid-cols-12 bg-offblack text-white min-h-screen content-center"
)

export const UserFormScreen = ({
  wrapperProps = {},
  label = "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  leftExpanded = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  leftContent = null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  footerContent = null,
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}) => {
  return (
    <UserFormWrapper {...wrapperProps}>
      <div className="col-span-full lg:col-span-6 h-0 lg:h-auto bg-offblack">
        <div className={clsx("lg:fixed z-10 inset-0 right-auto lg:h-full")}>
          <div className="absolute inset-0 bg-gradient-to-t from-offblack" />
          <H5
            as="p"
            className="absolute left-5 -rotate-90 top-1/2 -translate-x-1/2 uppercase hidden lg:block"
          >
            {label}
          </H5>
        </div>
      </div>
      <div
        className={clsx(
          "col-span-12 flex flex-col items-center relative z-[11]"
        )}
      >
        <div
          className={clsx(
            "mx-auto max-w-lg gap-x-8 flex-grow lg:max-w-screen-md xl:max-w-screen-xl w-full flex flex-col justify-start items-center"
          )}
        >
          {children}
        </div>
        {footerContent && (
          <footer
            className="w-full flex items-center justify-center sm:w-4/5 lg:max-w-screen-md xl:max-w-screen-xl  bg-offblack"
            style={{ maxWidth: "480px", marginBottom: "95px" }}
          >
            <div className="w-full flex" style={{ maxWidth: "380px" }}>
              {footerContent}
            </div>
          </footer>
        )}
      </div>
    </UserFormWrapper>
  )
}

export default UserFormScreen
