"use client"

import React, {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/navigation"
import Link from "next/link"
import clsx from "clsx"
import { addBreadcrumb, captureException } from "@sentry/nextjs"
import useAuth from "hooks/useAuth"
import useToast from "lib/useToast"
import API from "@/lib/api"
import * as Dialog from "@radix-ui/react-dialog"
import Input from "components/SerifForm/sansInput"

import { H1Med, H2Med, H4, Small } from "styles/Type"
import UserFormScreen from "screens/UserFormScreen"
import CheckmarkIcon from "@/icons/Checkmark"
import getLoginDestination from "@/lib/getLoginDestination"
import AmpersandMarkIcon from "@/icons/AmpersandMark"
import Google from "@/icons/Google"
import Apple from "@/icons/Apple"
import { stripAuthCookies } from "@/lib/serverActions"
import Image from "next/image"
import { Widget } from "@typeform/embed-react"
import CloseIcon from "@/icons/Close"

const EmailError = ({ email }) => {
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const onClick = useCallback(async () => {
    setIsSending(true)
    try {
      await API.post("/api/email_verify_re_send/", { email })
      setIsSending(false)
      setIsSent(true)
    } catch (error) {
      setIsSending(false)

      console.error("Error re-sending verification email:", error)
    }
  }, [email])

  return (
    <>
      Please verify your account by email.
      <button
        type="button"
        className={clsx(
          !isSent && !isSending && "underline",
          isSending && "opacity-60"
        )}
        onClick={onClick}
      >
        {isSending && "Sending..."}
        {isSent && "Sent!"}{" "}
        {isSent && <CheckmarkIcon className="w-3 h-3 inline-block ml-0.5" />}
        {!isSent && !isSending && "Re-send email"}
      </button>
    </>
  )
}

/**
 * Renders the login page component.
 *
 * @returns The login page component.
 */
export default function LoginPage() {
  const router = useRouter()
  const { addToast } = useToast()
  const { login, getUser } = useAuth()
  const [form, setForm] = useState({ email: "", password: "" })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isImageLoading, setIsImageLoading] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)
  const [loginUser, setLoginUser] = useState(null)
  const [emailError, setEmailError] = useState("")

  useEffect(() => {
    const cleanupCookies = async () => {
      try {
        await stripAuthCookies()
      } catch (err) {
        console.warn(err)
      }
    }
    cleanupCookies()
  }, [])

  const VerifyError = useMemo(
    () => <EmailError email={form.email} />,
    [form.email]
  )
  const TYPEFORM_ID = "Uk4EyaF3?typeform-welcome=0"
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [isSubmitted, setIsSubmitted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const onSubmitForm = useCallback(() => {
    // setIsSubmitted(true)
    setIsOpen(false)
  }, [])

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const { name, value } = e.target
    setForm((f) => ({ ...f, [name]: value }))

    if (name === "email") {
      if (value.trim() === "") {
        setEmailError("")
      } else if (!validateEmail(value)) {
        setEmailError("")
      } else {
        setEmailError("")
      }
    }
  }, [])

  const onSubmit = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault()
      setIsLoading(true)
      try {
        const LoginData = await login(form)
        setIsSubmitting(true)
        setIsLoading(false)
        setLoginUser(LoginData)
        const User = await getUser()
        const [team] = User.teams
        if (team?.type !== "Representative" || User?.status === "Guest") {
          const entities = [
            ...User.artist_profiles.map((u) => ({ ...u, type: "artist" })),
            ...User.brand_profiles.map((u) => ({ ...u, type: "brand" })),
          ]
          if (entities.length === 1) {
            // Uncomment and implement as needed
          }
        }
        router.push(getLoginDestination(User))
      } catch (err) {
        console.error(err)
        setIsSubmitting(false)
        setIsLoading(false)
        err.message = "Failed to login"

        if (err?.data?.non_field_errors?.[0] === "E-mail is not verified.") {
          err.message = "Error: Unverified account"
          err.data = {
            VerifyError,
          }
        }
        addBreadcrumb({
          category: "context",
          message: "@/pages/login/onSubmit.fn",
          level: "info",
        })
        captureException(err)
        addToast(err, "error")
      }
    },
    [getUser, VerifyError, addToast, form, login, router]
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const onSignInWithGoogle = async (provider) => {
    try {
      const response = await API.get(`api/user/login?provider=${provider}`)
      if (response.data && response.data.cognito_login_url) {
        window.location.href = response.data.cognito_login_url
      } else {
        console.error("No login URL returned")
      }
    } catch (err) {
      console.error(err)
      err.message = "Failed to login"
      addBreadcrumb({
        category: "context",
        message: "@/pages/login/onSignInWithGoogle.fn",
        level: "info",
      })
      captureException(err)
      addToast(err, "error")
    }
  }

  const isEmailValid = validateEmail(form.email.trim())

  return (
    <>
      <video
        className={clsx("fixed inset-0 h-full w-full object-cover")}
        src="/assets/videos/bg_video.mp4"
        autoPlay
        playsInline
        loop
        muted
      />

      <UserFormScreen
        wrapperProps={{ as: "form", onSubmit }}
        footerContent={
          isSubmitting && (
            <div className="bg-[rgba(22,22,22,1)] hero flex flex-col text-red h-full justify-center items-center fixed top-0 right-0 w-full z-[999] fadeInUpLazy">
              {isImageLoading && (
                <div className="w-[48px] h-[48px] rounded-full bg-gray-300 mx-auto" />
              )}
              <Image
                className="mx-auto"
                alt="Brennan Scarlett"
                src="/assets/rad-gif/rad-logo-gif.gif"
                width="67"
                height="57"
                sizes="40px"
                onLoad={() => setIsImageLoading(false)}
              />
              <H1Med className="text-cream text-center mt-[16px] mb-[16px] tracking-wide">
                Welcome back {loginUser?.first_name}
              </H1Med>
              <Small className="text-[#FFFFFF80] text-center mt-[16px] mb-[16px] tracking-wide uppercase text-[1.75rem] font-thin">
                please wait while we load your profile
              </Small>
            </div>
          )
        }
      >
        <div
          style={{
            maxWidth: "436px",
            height: "658px",
          }}
        >
          <div className="flex justify-center">
            <AmpersandMarkIcon width={36} height={20} className="mt-[100px]" />
          </div>
          <div>
            <div className="mt-[24px]">
              <H2Med
                as="h2"
                className="text-center text-[#FFFFF4] !font-normal leading-[25px] tracking-[-0.3px]"
              >
                Welcome back
              </H2Med>
              <H2Med
                as="h2"
                className="text-center text-[rgba(255,255,255,0.30)] !font-normal leading-[25px] tracking-[-0.3px] mb-[36px]"
              >
                Sign in to R&D
              </H2Med>
            </div>
            <div className="max-w-lg w-full pl-12 pr-12 ">
              <Input
                type="email"
                name="email"
                label="Enter email address"
                placeholder="Enter email address"
                value={form.email}
                onChange={onChange}
                className={clsx(
                  "mb-2 w-full rm_browser_default_styles !bg-[rgba(255,255,255,0.04)] !text-[14px] leading-[20px]",
                  emailError ? "border-red-500" : ""
                )}
                autoComplete="username"
                autoFocus
                ref={inputRef}
              />
              {emailError && (
                <Small className="text-red-500 mb-2">{emailError}</Small>
              )}

              {/* Password Field with Animation */}
              <div
                className={clsx(
                  "transition-all duration-500 ease-in-out",
                  isEmailValid
                    ? "opacity-100" // Visible state
                    : "max-h-0 opacity-0 mt-0 overflow-hidden" // Hidden state
                )}
              >
                <Input
                  type="password"
                  name="password"
                  label="Enter your password"
                  placeholder="Enter your password"
                  value={form.password}
                  onChange={onChange}
                  className="-mt-1 mb-[8px] w-full rm_browser_default_styles !bg-[rgba(255,255,255,0.04)] !text-[14px] leading-[20px]"
                  autoComplete="current-password"
                />
              </div>

              <H4
                as="button"
                type="submit"
                className={clsx(
                  "h-[48px] !text-[16px] !font-normal leading-[24px] tracking-[-0.2px] px-7 py-3 rounded-xl max-w-lg border border-white w-full transition-colors duration-300",
                  !isEmailValid || !form.password || isSubmitting || isLoading
                    ? "border-[#C6C6C1] text-greyBr bg-[#C6C6C1]"
                    : "hover:border-[#C6C6C1] bg-white text-offblack hover:bg-[#EEEBD7]"
                )}
                disabled={
                  !isEmailValid || !form.password || isSubmitting || isLoading
                }
              >
                {isLoading ? "Logging in..." : "Continue"}
              </H4>
            </div>
            <div className="pl-12 pr-12">
              <div className="flex justify-center align-middle gap-2 items-center mt-4 ">
                <div className="bg-white/10 h-[1px] w-full" />
                <Small className="text-white w-full text-center leading-[16px]">
                  or continue with
                </Small>
                <div className="bg-white/10 h-[1px] w-full" />
              </div>
              <div className="flex items-center align-middle mt-4 gap-2">
                <H4
                  as="button"
                  type="button"
                  onClick={() => onSignInWithGoogle("google")}
                  className="py-[12px] px-[28px] leading-[24px] h-[48px] border flex justify-center items-center gap-[4px] rounded-xl border-white border-opacity-20 text-white w-full hover:border-white text-opacity-20 bg-transparent disabled:bg-transparent transition-colors duration-300"
                >
                  <Google /> Google
                </H4>
                <H4
                  as="button"
                  type="button"
                  className="py-[12px] px-[28px] leading-[24px] h-[48px] border flex justify-center items-center gap-[4px] rounded-xl border-white border-opacity-20 text-white w-full hover:border-white text-opacity-20 bg-transparent disabled:bg-transparent transition-colors duration-300"
                  onClick={() => onSignInWithGoogle("apple")}
                >
                  <Apple width={16} height={16} /> Apple
                </H4>
              </div>
              <div className="mt-6">
                <Link
                  href="/password/forgot"
                  passHref
                  legacyBehavior
                  className="flex"
                >
                  <Small
                    as=""
                    className="text-center cursor-pointer text-[rgba(255,255,244,0.80)] leading-[20px] tracking-[0]"
                  >
                    Forgot Password?
                  </Small>
                </Link>
              </div>
              <div className="mt-[100px]">
                <Small className="text-white/30 mt-10 text-center">
                  By proceeding you acknowledge that you have read, understood
                  and agree to our{" "}
                  <Link href="/tos" passHref className="underline">
                    Terms and Conditions.
                  </Link>
                </Small>
                <Small className="text-white/30 mt-[24px] text-center flex gap-5 justify-center">
                  © 2025 R & D{" "}
                  <Link href="/privacy" passHref className="underline">
                    Privacy policy{" "}
                  </Link>
                  <Link href="/privacy" passHref className="underline">
                    Support
                  </Link>
                </Small>
              </div>
            </div>
          </div>
        </div>
      </UserFormScreen>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
        <Small className="mt-5 absolute right-10 top-8 -translate-y-4 text-[#FFFFFF66]">
          Don’t have an account?{" "}
          <Dialog.Trigger asChild>
            <span className="text-white">Join the waitlist</span>
          </Dialog.Trigger>
        </Small>
        <Dialog.Content
          forceMount
          className={clsx(
            "fixed right-0 top-0 h-full bg-offblack max-w-full",
            !isOpen && "translate-x-full opacity-0",
            "transition duration-500",
            "flex flex-col px-6 md:px-0 justify-center",
            "mr-px"
          )}
          style={{ width: 420 }}
        >
          <Widget
            id={TYPEFORM_ID}
            hideHeaders
            // hideFooter
            opacity={0}
            onSubmit={onSubmitForm}
            onEndingButtonClick={() => {}}
            className="flex flex-grow w-[420px]"
          />
          <Dialog.Close
            className="lg:hidden absolute top-5 right-5 p-2"
            title="Close"
          >
            <CloseIcon width="24" height="24" />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Root>
    </>
  )
}
