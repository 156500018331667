import { User } from "@/types/User"

export default function getLoginDestination(
  user: User,
  searchParams?: string | Record<string, string>
) {
  const params = new URLSearchParams(
    searchParams ||
      (typeof window !== "undefined" && window.location.search) ||
      ""
  )
  const returnTo = params.get("returnTo")
  if (user?.enable_2fa) {
    return `/dashboard?${params}`
  }
  if (user?.is_admin && !returnTo) {
    return "/dashboard"
  }
  if (user?.lead_status !== "Approved") {
    return "/portal"
  }
  if (!user?.metadata?.accepted) {
    return "/dashboard/welcome"
  }

  return `/dashboard?${params}`
}
